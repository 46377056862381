import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: grid;
  grid-row-gap: 48px;

  gap: ${({gap}) => `${gap}px`};
  grid-template-columns: ${({ maxCol }) => `repeat(${maxCol}, 1fr)`};
`;

function GridBox({ children, gap, col, ...rest }) {

  return (
    <Container maxCol={ col || 2} gap={gap} {...rest}>
      {children}
    </Container>
  )
}

export default GridBox;
